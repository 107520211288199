<template>
  <div>
    <v-btn @click.stop="$router.go(-1)" text class="black--text mb-2">
      <v-icon left>mdi-arrow-left</v-icon> Voltar à página inicial do debate
    </v-btn>

    <div class="main-post">
      <PostCard :post="mainPost" />
    </div>

    <div class="post-card" v-bind:key="post.id" v-for="post in relatedPosts">
      <PostCard :post="post" />
    </div>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false"
            >Ok</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="interactionDialog" persistent max-width="850">
      <InteractGameForm
        :interactionType="interactionType"
        :responseToThePostId="postId"
        :origin="'singlePostPage'"
        @close-dialog="interactionDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import phiIcon from "@/assets/fi.png";
import phiNegIcon from "@/assets/fiNeg.png";
import phiUpperIcon from "@/assets/phiUpper4.png";
import questionIcon from "@/assets/question.png";
import InteractGameForm from "@/components/Games/InteractGameForm.vue";
import PostCard from "@/components/Games/PostCard.vue";

export default {
  components: {
    InteractGameForm,
    PostCard,
  },
  computed: {
    postId() {
      return this.$route.params.postId;
    },
    gameId() {
      return this.$route.params.gameId;
    },
  },
  data() {
    return {
      loading: false,
      successDialog: false,
      interactionDialog: false,
      interactionType: null,
      phiIconSrc: phiIcon,
      phiNegIconSrc: phiNegIcon,
      phiUpperIconSrc: phiUpperIcon,
      questionIconSrc: questionIcon,
      editorOptions: {
        modules: { toolbar: false },
      },
      userIsModerator: false,
      mainPost: {
        content: "<p>Carregando conteúdo...</p>",
      },
      relatedPosts: [],
    };
  },
  methods: {
    async getRelatedPosts() {
      try {
        this.loading = true;

        const res = await this.$axios.get(
          `/jogos/${this.gameId}/posts/${this.postId}`
        );

        this.setData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setData(data) {
      const cpData = { ...data };

      this.relatedPosts = [...cpData.posts];

      this.mainPost = cpData.mainPost;

      this.userIsModerator = cpData.userIsModerator;
    },
    replacesPost(data) {
      const index = this.relatedPosts.findIndex(
        (p) => p.id === parseInt(data.id)
      );

      //remove o antigo objeto do array
      this.relatedPosts.splice(index, 1);

      /* se for o post principal, substitui os dados dele, se nao,
      deleta o antigo elemento do array e add os dados novos no lugar */

      if (parseInt(data.id) === parseInt(this.mainPost.id)) {
        this.mainPost = { ...data };
      } else {
        //adiciona data no lugar do que foi removido
        this.relatedPosts.splice(index, 0, { ...data });
      }
    },
    openInteractionDialog(type) {
      this.interactionType = type;
      this.interactionDialog = true;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  sockets: {
    connect: function () {
      this.$socket.emit("connectedInSinglePost", this.postId);
    },
    newPost: function (data) {
      this.relatedPosts = [{ ...data }, ...this.relatedPosts];
    },
    postDeleted: function (id) {
      /* se o post que foi deletado na página do jogo for o post pai dessa página,
      volta para a página anterior */
      if (this.postId == id) {
        this.$router.go(-1);
      } else {
        const index = this.relatedPosts.findIndex((p) => p.id === parseInt(id));

        this.relatedPosts.splice(index, 1);
      }
    },
    postEdited: function (data) {
      this.replacesPost(data);
    },
    validatedPost: function (data) {
      this.replacesPost(data);
    },
  },
  created() {
    this.$socket.emit("connectedInSinglePost", this.postId);
    this.getRelatedPosts();
  },
};
</script>

<style scoped>
.v-btn {
  color: #ffffff;
  text-transform: initial;
}

.card-title,
.card-actions {
  background-color: #f5f5f5;
}
</style>
